<template>
  <div class="countDown">
    <ul>
      <li class="num">{{h}}</li>
      <li class="colon">:</li>
      <li class="num">{{m}}</li>
      <li class="colon">:</li>
      <li class="num">{{s}}</li>
    </ul>
    <div class="time-title">答疑倒计时</div>
  </div>
</template>

<script>
export default {
  props: {
    h: String,
    m: String,
    s: String,
  },
};
</script>

<style lang="scss" scoped>
.countDown {
  color: #4e596f;
  display: flex;
  flex-direction: column;
  align-items: center;
  ul {
    font-size: 36px;
    font-weight: bold;
    display: flex;
    li {
      position: relative;
      height: 50px;
      line-height: 50px;
      &.colon {
        margin: 0 12px;
      }
      &.num::after {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        width: 38px;
        height: 6px;
        background-color: #4e596f;
        border-radius: 2px;
      }
    }
  }
  .time-title {
    font-size: 20px;
    margin: 20px 0 0;
  }
}
</style>
