<template>
  <div class="rate">
    <div class="rate-label">{{ label }}{{ grade }}分</div>
    <div class="rate-body">
      <img
        class="star"
        v-for="(item, i) in stars"
        :key="i"
        :src="item <= grade ? starActive : star"
      />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    label: String,
    grade: String,
  },
  data() {
    return {
      stars: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      star: require('../assets/img/star.png'),
      starActive: require('../assets/img/star_active.png'),
    };
  },
};
</script>

<style lang="scss" scoped>
.rate {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  &-label{
    width: 190px;
    text-align: right;
  }
  .star {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
}
</style>
