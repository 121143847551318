<template>
  <div class="articleInfo">
    <div class="articleInfo-top">
      <back-btn :handle-click="() => $router.push('/writing')" />
      <count-down v-if="restTime" :h="h" :m="m" :s="s" />
      <div></div>
    </div>
    <div class="articleInfo-con">
      <div class="articleBar" v-if="writeInfo.status === 6">
        <div class="articleBar-item" v-for="(item, i) in qaList" :key="i">
          <div class="articleBar-item-label">问题</div>
          <div class="articleBar-item-con">{{ item.question }}</div>
          <div v-if="item.imgUrl" class="flowRow">
            <div
              class="articleBar-item-img"
              v-for="(item, index) in item.imgUrl.split(',')"
              :key="index"
            >
              <img :src="item" alt="" />
              <div class="preview" @click="preview(item)">
                <img src="../../assets/img/preview_img.png" alt="" />
                点击放大图片
              </div>
            </div>
          </div>

          <div class="answer articleBar-item-con-line">
            解答：{{ item.answer }}
          </div>
        </div>
        <div class="articleBar-item">
          <div class="articleBar-item-label">问题</div>
          <el-input v-model="qaForm.question" />
          <div class="submit">
            <upload-file
              :imgUrl="qaForm.imgUrl"
              @onSuccess="(val) => (qaForm.imgUrl = val)"
              folder-code="39"
              :limit="5"
            />
            <el-button
              :disabled="disabled || !restTime"
              @click="submitQuestion"
              type="primary"
              >提交问题</el-button
            >
          </div>
        </div>
      </div>
      <div class="articleBar">
        <div class="articleBar-item">
          <div class="articleBar-item-label">
            <span>作文题目</span>
            <span v-text="types[writeInfo.writingType]"></span>
          </div>
          <div
            class="articleBar-item-con"
            v-html="writeInfo.subjectContent"
          ></div>
          <div
            class="articleBar-item-img"
            v-for="(item, i) in writeInfo.subjectUrl"
            :key="i"
            v-show="writeInfo.subjectUrl.length"
          >
            <img :src="item" alt="" />
            <div class="preview" @click="preview(item)">
              <img src="../../assets/img/preview_img.png" alt="" />
              点击放大图片
            </div>
          </div>
        </div>
        <div class="articleBar-item">
          <div class="articleBar-item-label">作文内容</div>
          <div
            class="articleBar-item-con"
            v-html="writeInfo.writingContent"
          ></div>
        </div>
      </div>
      <div
        class="articleBar"
        v-if="
          writeInfo.status === 6 &&
          (writeInfo.operaterTotalScore ||
            writeInfo.teacherTotalScore ||
            writeInfo.correctingContent)
        "
      >
        <div
          class="articleBar-item"
          v-if="
            writeInfo.operaterTotalScore ||
            writeInfo.teacherTotalScore ||
            writeInfo.teacherTotalScore === 0
          "
        >
          <div class="articleBar-item-label">
            作文评分：{{
              writeInfo.operaterTotalScore === 0 || writeInfo.operaterTotalScore
                ? writeInfo.operaterTotalScore
                : writeInfo.teacherTotalScore
            }}
          </div>
          <div class="articleBar-item-con">
            <my-rate label="写作任务完成情况：" :grade="rates[0] || 0" />
            <my-rate label="连贯与衔接：" :grade="rates[1] || 0" />
            <my-rate label="词汇丰富程度：" :grade="rates[2] || 0" />
            <my-rate label="语法多样性及准确性：" :grade="rates[3] || 0" />
          </div>
        </div>
        <div
          class="articleBar-item"
          v-if="
            writeInfo.operatorCorrectingContent || writeInfo.correctingContent
          "
        >
          <div class="articleBar-item-label">修改批注：</div>
          <div
            class="articleBar-item-con"
            v-html="
              writeInfo.operatorCorrectingContent || writeInfo.correctingContent
            "
          ></div>
        </div>
      </div>
      <div
        class="articleBar"
        v-if="
          writeInfo.status === 6 &&
          (writeInfo.taskResponse ||
            writeInfo.coherence ||
            writeInfo.grammar ||
            writeInfo.advice)
        "
      >
        <div class="articleBar-item">
          <div class="articleBar-item-label">作文点评</div>
          <div class="articleBar-item-sublabel">写作任务回应：</div>
          <div class="articleBar-item-con-line gray">
            {{ writeInfo.taskResponse }}
          </div>
          <div class="articleBar-item-sublabel">连贯与衔接：</div>
          <div class="articleBar-item-con-line gray">
            {{ writeInfo.coherence }}
          </div>
          <div class="articleBar-item-sublabel">词汇与语法：</div>
          <div class="articleBar-item-con-line gray">
            {{ writeInfo.grammar }}
          </div>
          <div class="articleBar-item-sublabel">提升建议：</div>
          <div class="articleBar-item-con-line gray">
            {{ writeInfo.advice }}
          </div>
        </div>
      </div>
    </div>
    <preview-img ref="previewImg" :cur-img="curImg" />
  </div>
</template>

<script>
import BackBtn from "@/components/BackBtn.vue";
import http from "@/utils/request";
import { connectState } from "@/utils/wyUtil";
import MyRate from "../../components/MyRate.vue";
import CountDown from "./components/CountDown.vue";
import PreviewImg from "./components/PreviewImg.vue";
import UploadFile from "./components/UploadFile.vue";

export default {
  components: {
    BackBtn,
    CountDown,
    MyRate,
    UploadFile,
    PreviewImg,
  },
  data() {
    return {
      h: "",
      m: "",
      s: "",
      qaForm: {},
      curImg: "",
      disabled: false,
      types: [
        "大作文",
        "小作文",
        "托福",
        "考研",
        "6级",
        "4级",
        "翻译",
        "高考",
        "中考",
        "小学",
        "专四",
        "专8",
        "摘要",
        "考博",
        "pets",
        "gre",
        "gmat",
        "sat",
        "act",
        "bec",
      ],
    };
  },
  created() {
    this.$store.dispatch("writing/getWriteInfo", this.$route.params.id);
  },
  computed: {
    ...connectState("writing", ["writeInfo", "qaList", "rates", "restTime"]),
  },
  watch: {
    writeInfo(val){
      console.log(val.subjectUrl, )
         
    },
    restTime(val) {
      if (val) {
        this.getTime(val);
      }
    },
    qaList(val) {
      if (val.length >= 3) {
        this.disabled = true;
      }
    },
  },
  methods: {
    getTime(time) {
      let s = time % 60;
      let m = Math.floor((time / 60) % 60);
      let h = Math.floor(time / 60 / 60);
      const interval = setInterval(() => {
        s += -1;
        if (s === -1) {
          s = 59;
          m += -1;
        }
        if (m === -1) {
          m = 59;
          h += -1;
        }
        this.h = h < 10 ? `0${h}` : h;
        this.m = m < 10 ? `0${m}` : m;
        this.s = s < 10 ? `0${s}` : s;
        if (s === 0 && m === 0 && h === 0) {
          clearInterval(interval);
          this.disabled = true;
        }
      }, 1000);
    },
    preview(url) {
      this.curImg = url;
      this.$refs.previewImg.init();
    },
    async submitQuestion() {
      if (!this.qaForm.question) {
        this.$message.error("请输入问题");
      } else {
        const data = {
          ...this.qaForm,
          correctingId: this.$route.params.id,
        };
        await http("/hcp/yida/writingCorrecting/submitQuestion", {
          method: "POST",
          data,
        });
        this.$message.success("提交问题成功");
        this.qaForm = {};
        this.$store.dispatch("writing/getWriteInfo", this.$route.params.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.articleInfo {
  &-top {
    width: 1200px;
    margin: 20px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-con {
    margin-top: 20px;
    background-color: #fff;
    padding: 20px 0;
    .flexRow{
      display:flex;
      flex-flow: row wrap;
    }
    .articleBar {
      width: 1140px;
      margin: 0px auto;
      background-color: #f8f8f8;
      padding: 15px 15px 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
      border-radius: 9px;
      color: #131b33;
      &-item {
        margin-bottom: 20px;
        &-img {
          margin-top: 15px;
          width: 200px;
          position: relative;
          display: inline-block;
          margin-right: 20px;
          img {
            width: 100%;
          }
          .preview {
            cursor: pointer;
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
            color: #fff;
            font-size: 14px;
            position: absolute;
            padding: 0 10px;
            top: 15px;
            right: 15px;
            background-color: rgba(51, 59, 80, 0.6);
            img {
              width: 15px;
              height: 15px;
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        &-label {
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;
          span:last-child {
            color: #3377ff;
            font-weight: normal;
            font-size: 14px;
            padding: 0 14px;
            height: 28px;
            line-height: 28px;
            border: 1px solid #3377ff;
            border-radius: 14px;
          }
        }
        &-sublabel {
          margin-bottom: 15px;
        }
        &-con-line {
          padding-bottom: 15px;
          border-bottom: 1px solid #979797;
          margin-bottom: 15px;
          &.gray {
            color: #4e596f;
          }
        }
        .submit {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
        }
        .answer {
          color: #4e596f;
          margin-top: 15px;
        }
      }
    }
  }
}
</style>
